/* @import url('https://fonts.googleapis.com/css2?family=Proza+Libre:ital,wght@1,700&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Proza+Libre:ital,wght@0,700;1,600;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200&family=Proza+Libre:ital,wght@0,700;1,600;1,700&display=swap');
*, :after, :before {
  box-sizing: border-box;
}

html {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  scroll-behavior: smooth;
  background-color: #F9F9F9;
  outline: 0px;
}



html,body
{
    width: 100%;
    height: 100%;
    margin: 0px;
    padding: 0px;
    overflow-x: hidden;
    background-color: #F9F9F9; 
    outline: 0px;
}



.app-wrapper {
  height: 100vh;
  width: 100vw;
  background-color: #f9f9f9;
  border: none;
  outline: 0px;

}

.menu-icon {
  color: #BDEDE0;
  font-size: 40pt;
}

.click {
  transform: rotate(45deg) 
}

.github-icon {
  color: #F9F9F9;
  font-size: 30pt;
  margin-right: 10px;
  margin-top: 3px;
  
}

.sticky {
	position: fixed;
	top: 0;
	width: 100%;
	height: 40px;
	z-index: 1;
	background-color: white;
	/*height: 6%;*/
	display: flex;

}

.upper {
  position: absolute;
}


.submit-feedback {
  color: #f9f9f9;
  font-size: 14pt;
}

.errorMessage {
  color: #a12e4a;
  font-size: 14pt;

}
